<template>
<div id="app" class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
  <nav id="site-navigation" class="has-branding main-navigation grid-container nav-align-right sub-menu-right" aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
    <div class="inside-navigation grid-container">
      <div class="navigation-branding">
        <div class="site-logo">
          <a href="https://gbwhatsapks.net/" title="GBWhatsApp" rel="home">
            <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/gb-logo.webp" title="GBWhatsApp">
          </a>
        </div>
        <p class="main-title" itemprop="headline">
          <a href="https://gbwhatsapks.net/" rel="home">
            GBWhatsApp
          </a>
        </p>
      </div>

      <div id="primary-menu" class="main-nav">
        <ul id="menu-primary-menu" class="menu sf-menu">
          <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
            <a href="https://gbwhatsapks.net/" aria-current="page">GB WhatsApp</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbwhatsapks.net/download-gbwhatsapp/">Download</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbwhatsapks.net/gb-whatsapp-old-versions/">Old Versions</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbwhatsapks.net/blogs/">Blogs</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div id="page" class="site grid-container container hfeed">
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="maincontent" class="site-main">
          <section id="section-1" class="section-content">
            <Breadcrumb :breadcrumbs="breadcrumbs" />

            <h1 class="section-1-blog-head">
              Download GB WhatsApp Old Versions | Latest 2024 to 2025 APKs
            </h1>

            <p class="writter-content">
              <a href="https://gbwhatsapks.net/" class="jump-url">GB WhatsApp</a> is one of the most popular modified versions of the official WhatsApp, offering advanced customization, privacy controls, and additional features that the original app lacks. However, some users prefer older versions of GB WhatsApp for better stability, compatibility, or to retain features removed in newer updates. If you're looking for previous APK releases of GB WhatsApp, this guide will help you find, download, and install the version that best suits your needs.
            </p>

            <!-- <div class="lazy-picture-container writer-banner full-width">
              <picture><img width="auto" height="auto" alt="50 million users prefer gb whatsapp" src="../assets/50-million-users-prefer-gb-whatsapp.webp"></picture>
            </div> -->
            <p class="writter-content">
              <strong>Why Download an Older Version of GB WhatsApp?</strong> Older versions of GB WhatsApp may be preferred for several reasons:
            </p>
            <ul>
              <li><strong>Stability</strong>: Newer updates may contain bugs that affect performance.</li>
              <li><strong>Compatibility</strong>: Older versions may work better on certain devices or Android versions.</li>
              <li><strong>Feature Retention</strong>: Some features may have been removed in the latest updates.</li>
              <li><strong>Lighter on Resources</strong>: Older versions may consume less RAM and storage.</li>
            </ul>

            <p class="writter-content">
              For users interested in downloading older versions of GB WhatsApp from 2024 to 2025, several versions are available:
            </p>
            <ul>
              <li><a href="#version-18.30" class="jump-url"><strong>GB WhatsApp Version 18.30</strong></a>: Released on December 9, 2024, with a size of 72 MB.</li>
              <li><a href="#version-18.20" class="jump-url"><strong>GB WhatsApp Version 18.20</strong></a>: Released on November 10, 2024, with a size of 72 MB.</li>
              <li><a href="#version-18.10" class="jump-url"><strong>GB WhatsApp Version 18.10</strong></a>: Released on October 5, 2024, with a size of 70 MB.</li>
              <li><a href="#version-18.00" class="jump-url"><strong>GB WhatsApp Version 18.00</strong></a>: Released on September 1, 2024, with a size of 68 MB.</li>
              <li><a href="#version-17.90" class="jump-url"><strong>GB WhatsApp Version 17.90</strong></a>: Released on August 15, 2024, with a size of 67 MB.</li>
            </ul>
            <p class="writter-content">
              These versions can be downloaded from reputable sources such as <a href="https://gbwhatsapks.net/" class="jump-url">gbwhatsapks.net</a>.
            </p>

            <h2 class="intro-title blog">
              Latest Version of GBWhatsapp APK 2025
            </h2>
            <div id="version-18.30" class="old-version-item">
              <div class="old-version-img">
                <img src="../assets/logo.webp" alt="gb whatsapp logo" />
              </div>
              <div class="old-version-info">
                <div class="old-version-title">
                  GB WhatsApp (Latest) Version 18.30 (Anti-Ban) | Size 72MB
                </div>
                <div class="old-version-detail">
                  Android 5.0+<br>Developer: <strong>AlexMods</strong><br>Updated: December 9, 2024
                </div>
              </div>
              <div class="old-version-download-wrapper">
                <a class="old-version-download" @click="downloadURL()">
                  Download
                </a>
              </div>
            </div>
            <div style="height: 30px" aria-hidden="true" class="wp-block-spacer" />


            <h2 class="intro-title blog">
              GBWhatsApp Old Versions
            </h2>
            <div class="old-version-wrapper">
              <div :id="version.id" class="old-version-item" v-for="(version, i) in versions" :key="i">
                <div class="old-version-img">
                  <img src="../assets/logo.webp" alt="gb whatsapp logo" />
                </div>
                <div class="old-version-info">
                  <div class="old-version-title">
                    {{ version.title }}
                  </div>
                  <div class="old-version-detail" v-html="version.detail">
                  </div>
                </div>
                <div class="old-version-download-wrapper">
                  <a class="old-version-download" @click="downloadURL()">
                    Download
                  </a>
                </div>
              </div>
            </div>

            <h2 class="intro-title blog">
              How to Install an Older Version of GB WhatsApp
            </h2>
            <ol>
              <li><strong>Uninstall Current Version</strong> (Optional but recommended)
                <ul>
                  <li> If you are already using GB WhatsApp, uninstall it to prevent conflicts.</li>
                </ul>
              </li>
              <li><strong>Enable Unknown Sources</strong>
                <ul>
                  <li> Go to <strong>Settings > Security > Install Unknown Apps</strong></li>
                  <li> Enable installation from unknown sources</li>
                </ul>
              </li>
              <li><strong>Download the APK File</strong>
                <ul>
                  <li> Click on the provided download link for your preferred version</li>
                  <li> Save the APK file on your device</li>
                </ul>
              </li>
              <li><strong>Install the APK</strong>
                <ul>
                  <li> Locate the downloaded file in your file manager</li>
                  <li> Tap the APK file and follow the installation prompts</li>
                </ul>
              </li>
              <li><strong>Verify Your Phone Number</strong>
                <ul>
                  <li> Open GB WhatsApp and enter your phone number</li>
                  <li> Verify it using the OTP sent to your number</li>
                </ul>
              </li>
              <li><strong>Restore Chat Backup (Optional)</strong>
                <ul>
                  <li> If you have a previous chat backup, restore it during setup</li>
                </ul>
              </li>
            </ol>

            <h2 class="intro-title blog">
              Frequently Asked Questions
            </h2>
            <!-- <FAQ :faqs="faqs" /> -->
            <div class="faq">
              <div v-for="(item, index) in faqs" :key="index" class="dropdown-faq">
                <div class="faq-title" @click="toggleFaq(index)">
                  <h3>{{ item.question }}</h3>
                  <img src="../assets/down-black.svg" alt="down" :class="{rotate: activeIndex===index}" loading="lazy">
                </div>
                <p v-if="activeIndex === index" class="content" v-html="item.answer">
                </p>
              </div>
            </div>

            <div style="height: 30px" aria-hidden="true" class="wp-block-spacer" />

          </section>
        </main>
      </div>
    </div>
  </div>

  <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
    <footer class="site-info">
      <div class="inside-site-info grid-container">
        <div class="footer-bar">
          <a href="https://gbwhatsapks.net/disclaimer/">Disclaimer</a>
          |
          <a href="https://gbwhatsapks.net/about-us/">About Us</a>
        </div>
        <div class="copyright-bar">
          2025 © All Rights Reserved <strong><a href="https://gbwhatsapks.net/">GBWhatsApp</a></strong>
        </div>
      </div>
    </footer>
  </div>
  <div v-if="$global.isMobileDevice()" style="height: 50px" aria-hidden="true" class="wp-block-spacer" />

  <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn" href="https://gbwhatsapks.net/download-gbwhatsapp/">
    Download GBWhatsapp APK
  </div>
</div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/pc.scss';
import Breadcrumb from '@/components/BreadCrumb.vue';
// import FAQ from '@/components/FAQ.vue';

export default {
  name: 'Home',
  components: {
    Breadcrumb,
    // FAQ
  },
  data() {
    return {
      pageName: 'home',
      showapk: false,

      apk: null,
      from: 'gb',
      filename: 'gb',
      activeIndex: null,

      faqs: [{
          "question": "What is GB WhatsApp?",
          "answer": "GB WhatsApp is a third-party, modified version of WhatsApp that offers enhanced features such as privacy options, themes, and customization."
        },
        {
          "question": "Is it safe to use older versions of GB WhatsApp?",
          "answer": "Older versions may function but can have security vulnerabilities or bugs. It's recommended to use the latest version for better security."
        },
        {
          "question": "How can I download GB WhatsApp old versions?",
          "answer": "Old versions can be found on third-party websites, but downloading from unofficial sources can pose security risks. Always ensure you download from trusted sources."
        },
        {
          "question": "Why should I use an old version of GB WhatsApp?",
          "answer": "Some users prefer older versions for stability or because they retain features removed in newer versions. Older versions might also have fewer bugs or security updates."
        },
        {
          "question": "How do I install an older version of GB WhatsApp if I already have the latest version?",
          "answer": "Uninstall the latest version first, download the older APK file, and install it. Remember to back up your chats before switching."
        },
        {
          "question": "Can I use GB WhatsApp without being banned?",
          "answer": "Using GB WhatsApp risks getting banned from the official app. To minimize risk, some users use a secondary number for GB WhatsApp."
        },
        {
          "question": "Can I restore my chats from old versions of GB WhatsApp?",
          "answer": "Yes, you can restore chat history from previous versions if you back up your chats before updating. Use Google Drive or local backups depending on the app's settings."
        },
        {
          "question": "Are there any features removed in newer versions of GB WhatsApp?",
          "answer": "Yes, some features might be removed in newer versions, such as certain customization options or privacy settings."
        },
        {
          "question": "How can I update GB WhatsApp?",
          "answer": "Manually update by downloading the latest APK from a third-party site, as updates aren't available through the official Google Play Store."
        }
      ],
      versions: [{
          "title": "GB WhatsApp Version 18.20 | Size 72MB",
          "detail": "Android 5.0+<br>Developer: <strong>AlexMods</strong><br>Updated: November 10, 2024",
          "id": "version-18.20"
        },
        {
          "title": "GB WhatsApp Version 18.10 | Size 70MB",
          "detail": "Android 5.0+<br>Developer: <strong>AlexMods</strong><br>Updated: October 5, 2024",
          "id": "version-18.10"
        },
        {
          "title": "GB WhatsApp Version 18.00 | Size 68MB",
          "detail": "Android 5.0+<br>Developer: <strong>AlexMods</strong><br>Updated: September 1, 2024",
          "id": "version-18.00"
        },
        {
          "title": "GB WhatsApp Version 17.90 | Size 67MB",
          "detail": "Android 5.0+<br>Developer: <strong>AlexMods</strong><br>Updated: August 15, 2024",
          "id": "version-17.90"
        },
        {
          "title": "GB WhatsApp Version 17.80 | Size 66MB",
          "detail": "Android 5.0+<br>Developer: <strong>AlexMods</strong><br>Updated: July 10, 2024",
          "id": "version-17.80"
        },
        {
          "title": "GB WhatsApp Version 17.70 | Size 65MB",
          "detail": "Android 5.0+<br>Developer: <strong>AlexMods</strong><br>Updated: June 5, 2024",
          "id": "version-17.70"
        },
        {
          "title": "GB WhatsApp Version 17.60 | Size 64MB",
          "detail": "Android 5.0+<br>Developer: <strong>AlexMods</strong><br>Updated: May 1, 2024",
          "id": "version-17.60"
        },
        {
          "title": "GB WhatsApp Version 17.50 | Size 63MB",
          "detail": "Android 5.0+<br>Developer: <strong>AlexMods</strong><br>Updated: April 10, 2024",
          "id": "version-17.50"
        }
      ]
    };
  },
  computed: {
    breadcrumbs() {
      return this.$route.matched.reduce((acc, route) => {
        const crumbs = route.meta.breadcrumb || [];
        return acc.concat(crumbs);
      }, []);
    }
  },
  mounted() {
    this.from = this.$global.GetQueryString('from') || this.from;
    this.filename = this.$global.GetQueryString('filename') || this.filename;
    console.log(this.$global.GetQueryString('from'));
    if (this.$global.GetQueryString('from')) {
      this.getAPK();
    } else {
      this.getOfficalApk();
    }
  },
  methods: {
    toggleFaq(index) {
      console.log(index);
      if (this.activeIndex === index) {
        this.activeIndex = null
      } else {
        this.activeIndex = index
      }
    },
    downloadURL() {
      console.log(this.apk);
      // this.showapk = true
      if (this.apk) {
        this.$global.download(this.apk);
      }
    },

    getAPK() {
      this.$server
        .getAPPInfo({
          promotionChannel: this.from,
          promotionName: this.filename,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.apk = res.data.apk;
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    getOfficalApk(type = 1, domain = null, appname = null) {
      this.$server
        .getOfficalApk({
          domain: domain || 'gbwhatsapp.chat',
          appName: appname || 'GB_gbwhatsapp.chat',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    jump(url) {
      window.location.href = url;
    },
  },
};
</script>

<style lang="scss" scoped>
.old-version-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 30px;
}

.old-version-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;

  .old-version-img {
    img {
      width: 100px;
      height: 100px;
      border-radius: 8px;
    }
  }

  .old-version-info {
    flex: 1;
    padding: 0 20px;

    .old-version-title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .old-version-detail {
      font-size: 16px;
      color: #666;
      margin-bottom: 5px;
    }
  }

  .old-version-download-wrapper {
    display: flex;
    align-items: flex-end;

    .old-version-download {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      padding: 10px 20px;
      border-radius: 8px;
      background-color: #2c3338;
      color: #fff;
      font-size: 17px;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background-color: #4CB8BA;
      }
    }
  }
}

@media (max-width: 768px) {
  .old-version-item {
    display: flex;
    flex-direction: column;

    .old-version-info {
      padding: 0;
      margin-bottom: 8px;
    }
  }
}
</style>
